<template>
	<v-container v-if="open">
		<section>
			<img src="../assets/logo-info-icon.svg" alt="System Info" />
			<div class="w-100 d-flex g-2 flex-column flex-sm-row align-sm-center justify-space-between">
				<div class="d-flex flex-wrap">
					<p class="font-weight-bold mr-2">{{ notice }}</p>
					<p>For updates, <a href="https://twitter.com/arkbluecross">visit us on X</a></p>
				</div>
				<v-btn variant="outlined" @click="dismiss" class="secondary">Dismiss</v-btn>
			</div>
		</section>
	</v-container>
</template>

<script>
import { API } from '../services/api/axios.js'
import { session } from '@/utils/utils.js'
import sanitizeHtml from 'sanitize-html'

const routesWithBanner = ['/login', '/select-plan', '/medical/dashboard', '/dv-dashboard']

export default {
	data() {
		return {
			open: false,
			notice: null
		}
	},
	watch: {
		$route(to) {
			let dismissedMode = session.get('dismissed_mode')
			if (!dismissedMode?.mode) {
				this.displayNotice()
			}
		}
	},
	beforeMount() {
		let dismissedMode = session.get('dismissed_mode')
		if (!dismissedMode?.mode) {
			this.getUserNotice()
		}
	},
	methods: {
		dismiss() {
			session.set('dismissed_mode', { mode: true })
			this.open = false
		},
		displayNotice() {
			if (routesWithBanner.includes(this.$route.path) && this.notice?.length > 0) {
				this.open = true
			} else {
				this.open = false
			}
		},
		async getUserNotice() {
			API({
				url: 'public/user-notice',
				methods: 'GET',
				eCode: 'BP-NFA-010'
			}).then((res) => {
				this.notice = this.sanitize(res.notice)
				// this.notice = "Test message";
				this.displayNotice()
			})
		},
		sanitize(html) {
			return sanitizeHtml(html)
		}
	}
}
</script>

<style lang="scss" scoped>
.v-container {
	padding-block: 0.7rem;
	border-bottom: 1px solid $neutral-300;
}

section {
	width: 100%;
	max-width: 1280px;
	display: flex;
	align-items: center;
	flex-direction: row;
	padding: 8px 0;
	margin: 0 auto;
	font-size: 14px;
	gap: 16px;
}

@media only screen and (max-width: $small-glass) {
	section {
		align-items: flex-start;
	}
	.v-container {
		padding: 0 !important;
	}
}
</style>
