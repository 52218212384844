import { API } from '../axios'

export const fhirAPI = async (props) => {
	const { url, method, headers, body, eCode } = props
	const baseURL = import.meta.env.VITE_FHIR_URL

	return await API({
		method,
		url: `${baseURL}/${url}`,
		headers,
		body,
		eCode
	})
}
